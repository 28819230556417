/* Common Imports */

import { makeStyles } from "@material-ui/core/styles";
import React from "react";
/* Component Imports */

import { Button, Typography } from "@material-ui/core";
import VisitModal from "../../modals/VisitModal";

import { PhoneOutlined } from "@material-ui/icons";
import AppConfig from "../../../AppConfig";
import HomepageDownloadModal from "../../modals/HomepageDownloadModal";

const useStyles = makeStyles((theme) => ({
  megaContainer: {
    background: "#FAFAFA",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      padding: "0rem 0.5rem 1rem 0.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0rem 1.5rem 1rem 1.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 3rem 1rem 3rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "10rem 4rem 1rem 4rem",
    },
    /* XLG breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
      padding: "0rem 5rem 1rem 5rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 86)]: {
      padding: "5rem 5rem 1rem 5rem",
    },
    /* Full HD breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 1rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      padding: "0rem 36rem 1rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      padding: "0rem 80rem 1rem 72rem",
    },
    // background: "#fafafa",
    margin: "0rem 0rem 6rem 0rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0rem 3rem 0rem",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      padding: "0.2rem 0rem 0rem 0rem",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "2rem 0rem 2rem 0rem",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "1.5rem 0rem 1rem 0rem",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },
  image: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    objectFit: "contain",
  },
  heading: {
    color: "#0FCA56",
    // background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    margin: "0rem 0rem 0.5rem 0rem",
    fontWeight: 700,
    fontSize: "2.5rem",
    lineHeight: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
      lineHeight: "2.125rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.75rem",
      lineHeight: "2.125rem",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    margin: "0em 0.5rem 0rem 0rem",
    gap: "2rem",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0rem 0rem 0rem",
    },
  },
  primaryButton: {
    background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    color: "#FFFFFF",
    borderRadius: "0rem",
    borderWidth: "0px",
    fontSize: "1.2rem",
    fontWeight: "500",
    textTransform: "capitalize",
    width: "70%",
    padding: "0.65rem 1rem",
    "&:hover": {
      background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
      color: "#FFFFFF",
      textDecoration: "underline",
      textUnderlineOffset: "3px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.2rem",
      width: "85%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      width: "100%",
    },
  },
  secondaryButton: {
    background: "#FFFFFF",
    color: "#0FCA56",
    borderRadius: "0rem",
    border: "solid 1px #0FCA56",
    borderWidth: "2px",
    fontSize: "1.2rem",
    fontWeight: "500",
    textTransform: "capitalize",
    width: "70%",
    "&:hover": {
      background: "#FFFFFF",
      textDecoration: "underline",
      textUnderlineOffset: "3px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.2rem",
      width: "70%",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1rem",
      width: "75%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      width: "100%",
    },
  },
  imageSection: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      objectFit: "contain",
      // height: "240px",
      // width: "240px",
    },
  },
}));

const SectionFour = () => {
  const classes = useStyles();
  const [openDownload, setOpenDownload] = React.useState(false);
  const [openContact, setOpenContact] = React.useState(false);

  const handleClose = () => {
    setOpenDownload(false);
  };
  const handleCloseVisit = () => {
    setOpenContact(false);
  };
  const handleOpen = () => {
    setOpenDownload(true);
  };
  // const handleOpenVisit = () => {
  //   setOpenContact(true);
  // };

  return (
    <React.Fragment>
      <HomepageDownloadModal
        open={openDownload}
        onClose={handleClose}
        source="Download PDF"
        submitCallback={(data) => {
          setOpenDownload(false);
        }}
      />
      <VisitModal
        open={openContact}
        onClose={handleCloseVisit}
        source="Book Site Visit"
        submitCallback={(data) => {
          setOpenContact(false);
        }}
      />
      <div className={classes.megaContainer}>
        <div className={classes.sectionContainer}>
          <div className={classes.text}>
            <Typography variant="h3" component="h3" className={classes.heading}>
              Know more about Sentient Developers
            </Typography>
            <div className={classes.container}>
              <div className={classes.image}>
                <img
                  src="/images/icons/heavie/index/sentient-developers.webp"
                  alt={AppConfig.imageAltText}
                  width={300}
                  height={215}
                  loading="lazy"
                  referrerPolicy="no-referrer"
                  className={classes.imageSection}
                />
              </div>
              <div className={classes.buttons}>
                <Button
                  href=""
                  title="Download Brochure"
                  rel="noopener"
                  className={classes.secondaryButton}
                  onClick={handleOpen}
                  endIcon={
                    <img
                      className={classes.btnIconDownload}
                      src="/images/icons/heavie/index/download_green.svg"
                      alt={AppConfig.imageAltText}
                      width={24}
                      height={24}
                      loading="lazy"
                      referrerPolicy="no-referrer"
                    />
                  }
                >
                  Download Brochure
                </Button>
                {/* <Button
                  href=""
                  title="Call Sentient"
                  rel="noopener"
                  className={classes.primaryButton}
                  onClick={handleOpenVisit}
                  endIcon={
                    <img
                      className={classes.btnIconVisit}
                      src="/images/icons/heavie/index/location.svg"
                      alt={AppConfig.imageAltText}
                      width={24}
                      height={24}
                      loading="lazy"
                      referrerPolicy="no-referrer"
                    />
                  }
                >
                  Book Site Visit
                </Button> */}
                  <Button
                              variant="contained"
                            href={`tel:08088939039`}
                              title="Call Now"
                              rel="noopener"
                              className={classes.primaryButton}
                              startIcon={<PhoneOutlined />}
                            >
                              Call Now
                            </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionFour;
